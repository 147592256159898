<template>
  <div>
    <card :title="true" :titleClass="'mb-4'" v-if="currentClass">
      <template v-slot:title>
        <div class="flex items-center" style="width: 50%;">
          <h4 class="mb-0">{{ currentClass.name }}</h4>
        </div>
        <copy-link
          title="Class link:"
          :value="'https://newtonteach.me/'+currentClass.id"
          :class="'flex-grow'"
        />
      </template>

      <div class="flex items-center justify-end mb-5">
        <btn
          value="Reports"
          class="fz-normal primary-light-bg text-white hover:text-white btn-small"
        />
        <btn
          value="Create Lesson"
          class="fz-normal secondary_dark_bg text-white hover:text-white btn-small ml-3"
        />
      </div>

      <div class="flex items-center justify-between my-5">

        <div class="flex items-center cursor-pointer">
          <h5 class="font-semibold text-blue-100 mb-0 mr-1">Show: This week</h5>
          <BaseIcon name="arrowDown" viewBox="0 0 10 6" class="fill-blue-100 w-2"/>
        </div>

        <div class="flex gap-3 small-text text-gray-800">
          <div class="flex items-center">
            <BaseIcon name="circle" viewBox="0 0 12 12" class="fill-danger-500 w-2.5 h-2.5"/>
            <h6 class="font-normal mb-0 ml-1">Below 30%</h6>            
          </div>
          <div class="flex items-center">
            <BaseIcon name="circle" viewBox="0 0 12 12" class="fill-orange-200 w-2.5 h-2.5"/>
            <h6 class="font-normal mb-0 ml-1">30-49%</h6>            
          </div>
          <div class="flex items-center">
            <BaseIcon name="circle" viewBox="0 0 12 12" class="fill-blue-400 w-2.5 h-2.5"/>
            <h6 class="font-normal mb-0 ml-1">50-79%</h6>            
          </div>
          <div class="flex items-center">
            <BaseIcon name="circle" viewBox="0 0 12 12" class="fill-success-800 w-2.5 h-2.5"/>
            <h6 class="font-normal mb-0 ml-1">80-100%</h6>            
          </div>         
        </div>

      </div>
    </card>

    <card v-else>
      <p class="fz-normal gray-color">
        Your classes list is empty
      </p>
    </card>
    
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  computed: {
    currentClass: function () {
      return (
        this.$store.getters.GET_CURRENT_CLASS[0] ||
        this.$store.getters.GET_CLASSES[0]
      )
    },
  },
  components: {
    BaseIcon,
  },
}
</script>

<style lang="scss" scoped></style>
